
  .banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 20px 50px; 
    box-sizing: border-box;
    gap: 20px;
}

.textContainer {
    min-width: 250px; 
    display: flex;
    max-width: 100%; 
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left; 
    padding: 10px;
 
}

.textContainer h1, .textContainer p {
    margin: 0 0 10px 0;
    text-align: left;
}

.textContainer h1 {
    font-size: 2.5rem;
  color: #128c78;
}

.textContainer p {
    font-size: 1rem; 
    color: #000;
}
.applink{
    color: #128c78;
    font-weight: bold;
    cursor: pointer;
}
.applink:hover{
    color: #FDC822;
}
.input, .textarea {
    width: 100%; 
    padding: 8px; 
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; 
    min-width: 250px; 
    transition: background-color 0.3s ease;
}
.input :focus, textarea:focus{
    border-color: #128c78;
}

.supportButton {
    padding: 10px 20px;
    background-color: white;
    color: #128c78;
    border: 2px solid #128c78;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.supportButtonHover:hover {
   background-color: #128c78;
   color: white;
}

.imageContainer {
    flex: 1;
    min-width: 250px;
    display: flex;
    justify-content: center;
    padding: 10px;
   
}

.bannerImage {
    max-width: 100%;
    height: auto;
    transform: rotatey(180deg);
   
  }

@media (max-width: 768px) {
    .banner {
        flex-direction: column;
        padding: 10px;
    }

    .textContainer {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .textContainer h1 {
        font-size: 20px;
    }

    .textContainer p {
        font-size: 14px;
    }

    .supportButton {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .banner {
        padding: 10px;
    }

    .textContainer h1 {
        font-size: 18px;
    }

    .textContainer p {
        font-size: 12px;
    }

    .input, .textarea {
        padding: 8px; 
    }

    .supportButton {
        font-size: 12px;
        padding: 8px 16px;
    }
}

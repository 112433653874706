 .privacyContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 50px;
    box-sizing: border-box;
  }
  
  .textContainer {
    flex: 1;
    min-width: 250px;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
  }
  
  .heading {
    font-size: 3.3rem;
    font-weight: bold;
    color: #128c78;
    margin: 0 0 10px 0;
    text-align: left;
  }
  
  .textContainer h5 {
    font-size: 16px;
    color: #000;
    margin: 0 0 20px 0;
    text-align: left;
  }
  
  .updateDate{
    color: #128c78;
  }
  .updateDate:hover{
    color: #FFB804;
  }
  .textContainer p {
    font-size: 16px;
    color: #000;
    margin: 0 0 20px 0;
    text-align: left;
  }
  
  .imageContainer {
    flex: 1;
    min-width: 250px;
    display: flex;
    justify-content: center;
  }
  
  .bannerImage {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .textContainer {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .heading {
      font-size: 2rem;
    }
  
    .textContainer h5,
    .textContainer p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 1.8rem;
    }
  
    .textContainer h5,
    .textContainer p {
      font-size: 12px;
    }
  }
   

     /* Interpretation and Definitions */

     .profileContainer {
        color: #000;
        padding: 2rem;
        box-sizing: border-box;
        font-family: Arial, sans-serif;
        justify-content: center;
        text-align: left;
        /* max-height: 600px;  */
        /* overflow-y: auto;  */
      }
      
      .profileHeader {
        border-left: 4px solid #128c78;
        padding-left: 1rem;
        margin-bottom: 1rem;
      }
      
      .profileTitle {
        font-size: 2rem;
        margin-top: 2rem;
        font-weight: bold;
        color: #128c78;
      }
      
      .profileLocation,
      .profileDates {
        margin: 0;
        font-size: 1rem;
      }
      
      .profileList {
        list-style-type: none;
        padding: 0;
        margin: 1rem 0;
      }
      
      .profileList li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        font-size: 1rem;
      }
      
      .profileList li svg {
        flex-shrink: 0;
        margin-right: 0.5rem;
      }
      
      @media (max-width: 768px) {
        .profileTitle {
          font-size: 1.5rem;
        }
      
        .profileLocation,
        .profileDates {
          font-size: 0.875rem;
        }
      
        .profileList li {
          font-size: 0.875rem;
        }
      }
      
      @media (max-width: 480px) {
        .profileTitle {
          font-size: 1.25rem;
        }
      
        .profileLocation,
        .profileDates {
          font-size: 0.75rem;
        }
      
        .profileList li {
          font-size: 0.75rem;
        }
      }
      

  /* Usage Data */


  .usageDataContainer {
    color: #000;
    padding: 2rem;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    justify-content: center;
    text-align: left;
    height: 80vh; 
    overflow-y: auto; 
  }
  
  .usageDataHeader {
    border-left: 0.4rem solid #128c78;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .usageDataTitle {
    font-size: 2rem;
    margin-top: 2rem;
    font-weight: bold;
    color: #128c78;
  }
  
  .usageDataSection {
    margin: 0;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .usageDataList {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .usageDataList li {
    display: flex;
    align-items: flex-start; 
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .usageDataList li svg {
    flex-shrink: 0; 
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .usageDataTitle {
      font-size: 1.5rem;
    }
  
    .usageDataSection {
      font-size: 0.875rem;
    }
  
    .usageDataList li {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .usageDataTitle {
      font-size: 1.25rem;
    }
  
    .usageDataSection {
      font-size: 0.75rem;
    }
  
    .usageDataList li {
      font-size: 0.75rem;
    }
  }
  
  /* Information from Third-Party Social Media Services */















  /* Contact Us */

  .contactContainer {
    color: #000;
    padding: 2rem;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    justify-content: center;
    text-align: left;
    height: 80vh; 
    overflow-y: auto; 
  }
  
  .contactHeader {
    border-left: 0.4rem solid #128c78;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  
  .contactTitle {
    font-size: 2rem;
    margin-top: 2rem;
    font-weight: bold;
    color: #128c78;
  }
  
  .emailsupport{
    color: #128c78;
    font-weight: bold;cursor: pointer;
 text-decoration: none;
  }
  .contactSection {
    margin: 0;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .contactButton {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #128c78;
    background-color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    text-align: center;
    margin-top: 1rem;
    text-decoration: none;
    border: 2px solid #128c78;
  }
  
  .contactButton:hover {
    background-color: #0f705d;
    color: white;
  }
  
  @media (max-width: 768px) {
    .contactTitle {
      font-size: 1.5rem;
    }
  
    .contactSection {
      font-size: 0.875rem;
    }
  
    .contactButton {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .contactTitle {
      font-size: 1.25rem;
    }
  
    .contactSection {
      font-size: 0.75rem;
    }
  
    .contactButton {
      font-size: 0.75rem;
    }
  }
  
  



  .bannerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* padding: 0rem 2rem;  */
    padding: 0px 50px;
    box-sizing: border-box;
    background-color: #FFB804;
    height: auto; 
    gap: 1rem; 
  }
  
  .imageContainer {
    flex: 1;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .bannerImage {
    max-width: 100%;
    height: auto;
  }
  
  .textContainer {
    flex: 1;
    margin-top: 0;
  }
  
  .heading1 {
    font-size: 1.5rem;
    color: #000;
    margin: 0; 
  }
  
  .heading2 {
    font-size: 1.25rem;
    color: #000;
    margin: 0;
  }
  
  .highlight {
    color: #128c78;
    font-weight: bold;
  }
  
  .paragraph {
    font-size: 1rem;
    color: #000;
    margin: 0.5rem 0; 
  }
  
  @media (min-width: 768px) {
    .bannerContainer {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem; 
      gap: 2rem; 
    }
  
    .textContainer {
      text-align: left;
    }
  
    .heading1 {
      font-size: 2rem;
    }
  
    .heading2 {
      font-size: 1.75rem;
    }
  
    .paragraph {
      font-size: 1.25rem;
    }
  }
  
  @media (min-width: 1024px) {
    .heading1 {
      font-size: 2.5rem;
    }
  
    .heading2 {
      font-size: 2rem;
    }
  
    .paragraph {
      font-size: 1.5rem;
    }
  }
  


  /*  What do you need to find?*/
  
  .content {
    text-align: center;
    padding: 2rem;
  }
  
  .content h1 {
    font-size: 2.5rem;
    color: #128c78;
    margin: 0.5rem 0;
  }
  
  .content h2 {
    font-size: 2rem;
    color: #FFB804;
    margin: 10px 0;
  }
  
  .content p {
    font-size: 1rem;
    color: #000;
    max-width: 800px;
    margin: 0 auto 20px auto;
    text-align: left;
  }
  
  .trendingButton {
    padding: 10px 20px;
    border: 2px solid #128c78;
    border-radius: 5px;
    color: #128c78;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 0;
  }
  
  .trendingButton:hover {
    background-color: #128c78;
    color: white;
  }
  
  @media (max-width: 768px) {
    .content h1 {
      font-size: 1.5rem;
    }
  
    .content h2 {
      font-size: 1.2rem;
    }
  
    .content p {
      font-size: 0.9rem;
    }
  
    .trendingButton {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .content h1 {
      font-size: 1.2rem;
    }
  
    .content h2 {
      font-size: 1rem;
    }
  
    .content p {
      font-size: 0.8rem;
    }
  
    .trendingButton {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  

.banner {
    background-color: #f5f5f5;
    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 0.5em;
}

.textContainer {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 2rem;
    color: #128c78;
}



.description, .descriptionHindi {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1rem;
    line-height: 1.4;
}
.appLink{
    color: #128c78;
  font-weight: bold;
  cursor: pointer;
}
.appLink:hover{
    color: #FFB804;
   font-weight: bold;
    cursor: pointer;
}

.contactButton {
    background-color: white;
    color: #128c78;
    border: 2px solid #128c78;
    padding: 0.6em 2em;
    font-size: 1.25rem;
    border-radius: 0.5em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contactButtonHover:hover {
    background-color: #128c78;
    color: white;
}

@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
    }

    .description, .descriptionHindi {
        font-size: 1rem;
    }

    .contactButton {
        padding: 0.8em 1.6em;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .banner {
        padding: 2rem 1rem;
    }

    .title, .description, .descriptionHindi {
        font-size: 0.9rem;
    }

    .contactButton {
        padding: 0.6em 1.2em;
        font-size: 0.9rem;
    }
}

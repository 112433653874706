

.featuresSection {
    background-color: #f5f5f5;
    padding: 30px;
  }
  
  .cardContainer {
    display: flex !important; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
  }
  
  .image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .textContainerNews {
    margin-top: 15px;
    /* text-align: left; */
    text-align: center;
    padding: 0 10px;
  }
  
  .textContainerNews h2 {
    font-size: 1.2rem;
    color: #128c78;
    margin: 10px 0;
  }
  
  .textContainerNews p {
    font-size: 1rem;
    color: #666;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .featuresSection {
      flex-direction: column;
      align-items: center;
    }
  
    .cardContainer {
      width: 90%;
      margin: 10px 0;
    }
  }
  
  .OverviewAllType {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #128c78;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .OverviewAllType:hover {
    background-color: darkred;
  }
  
  @media (max-width: 768px) {
    .OverviewAllType {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
        /* online business container */
        .banner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f9f9f9;
          padding: 20px;
          /* border-radius: 10px;     */
          margin: 20px;
        }
        
        .textContainer {
          max-width: 60%;
        }
        
        h1 {
          font-size: 2rem;
          color: #000;
          margin-bottom: 10px;
        }
        
        .highlight {
          color: #128c78;
        }
        
        p {
          font-size: 1.2rem;
          color: #666;
          margin-bottom: 20px;
        
        }
        
        .callToAction {
          background-color: #128c78;
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 1rem;
          border-radius: 5px;
        }
        
        .imageContainer {
          max-width: 35%;
        }
        
        .image {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
        
        @media (max-width: 768px) {
          .banner {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
        
          .textContainer {
            max-width: 100%;
          }
        
          .imageContainer {
            max-width: 100%;
            margin-top: 20px;
          }
        }
.sectioncontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  padding: 0% 2%;
   
    background: #FFB804;
    color: #333;
  }
  
  .title {
    font-size: 2rem;
    text-align: left;
    margin-bottom: 1rem;
    /* background: #000; */
  }
  
  .highlight {
    color: #128c78;
  }
  
  .description {
    font-size: 1.2rem;
    text-align: left;
    max-width: 800px;
    margin-bottom: 2rem;

  }
  
 
  
  .rating {
    height: auto;
    width: 10rem; 
  }
  
  .logo {
    height: auto;
    width: 5rem;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
      padding: 0 1rem;
    }
  
    .rating, .logo {
      width: 8rem; 
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }
  
    .description {
      font-size: 0.9rem;
      padding: 0 0.5rem;
    }
  
    .rating, .logo {
      width: 6rem; 
    }
  }


  /* card scrolling section */


  

  .featuresSection {
    background-color: #f5f5f5;
    padding: 30px;
   
  }
  .skillsGrid{
    padding: 10px;
   
  }
  .cardContainer {
    display: flex !important; 
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
   
  }
  
  
  .card {
    background-color: #fff;
  
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    gap: 20px;
  }
  
  .skillCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 250px; 
    margin: 0 10px; 
  }
  
  .skillLogo {
    font-size: 4rem;
    color: #128c78;
    margin-bottom: 1rem; 
  }
  
  .skillName {
    font-size: 1rem;
    color: #128c78;
    font-weight: bold;
  }
  
  .image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .textContainerNews {
    margin-top: 15px;
    text-align: left;
    padding: 0 10px;
  }
  
  .textContainerNews h2 {
    font-size: 1.2rem;
    color: #128c78;
    margin: 10px 0;
  }
  
  .textContainerNews p {
    font-size: 1rem;
    color: #666;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .featuresSection {
      flex-direction: column;
      align-items: center;
    }
  
    .cardContainer {
      width: 90%;
      margin: 10px 0;
    }
  
    .skillCard {
      max-width: 100%;
      margin: 10px 0; 
    }
  }
  
  

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #128c7e;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1; 
}

.logo {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 60px;
  object-fit: cover;
}

.navItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.navLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navLinks li {
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.navLinks p {
  text-decoration: none;
  color: #ffb804;
  margin-left: 10px; 
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.searchInput {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.authButtons {
  display: flex;
  margin-left: 15px;
}

.login,
.register {
  background-color: white;
  color: #128c7e;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 10px;
}

.burgerIcon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  margin-top: 5px;
  z-index: 2; 
}

/* Mobile Styles */

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navItems {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #128c7e;
    padding-top: 60px; 
   
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 1; 
    justify-content: flex-start;
    text-align: left;
    
  }

  .navItems.open {
    transform: translateX(0);
  }
  .iconSidebar {
    font-size: 1em; 
  }

  .navLinks {
    flex-direction: column;
    width: 100%;
  
  
     
  
    
  }

  .navLinks li {
  
    margin: -10px 0px;
    
    padding-left: 20px;

  }

  .navLinks li p {
    display: inline; 
    font-size: 1rem;
  }

  .navLinks li svg {
    display: inline; 
  }

  .authButtons {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
  }

  .login,
  .register {
    width: 100%;
    margin: 5px 0;
  }

  .searchContainer {
    width: 100%;
    margin-top: 10px;
  }

  .searchInput {
    width: 100%;
    margin-right: 0;
  }

  .burgerIcon {
    display: block;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .closeBtn {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 36px;
    cursor: pointer;
    color: white;
  }
}

/* Desktop Styles */
@media (min-width: 769px) {
  .navLinks {
    flex-direction: row;
  }

  .navLinks li p {
    display: inline;
  }

  .navLinks li svg {
    display: none;
  }

  .navItems {
    display: flex;
    justify-content: flex-end;
  }

  .authButtons {
    flex-direction: row;
    margin-left: 15px;
  }

  .burgerIcon {
    display: none;
  }
}




.carouselContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.carousel {
  width: 100%;
  height: auto; 
  position: relative;
}

.slides {
  display: flex;
  width: 100%;
  height: auto; 
  transition: transform 0.3s ease-in-out;
}

.slideImage {
  width: 100%;
  height: auto; 
  object-fit: cover;
}

.navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 20px;
  box-sizing: border-box;
}

.navButton {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navButton:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
}

.navButton:active {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 1200px) {
  .carousel {
    height: auto; 
  }
}

@media (max-width: 992px) {
  .carousel {
    height: auto; 
  }
}

@media (max-width: 768px) {
  .carousel {
    height: auto; 
  }

  .navButton {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .carousel {
    height: auto; 
  }

  .navButton {
    width: 30px;
    height: 30px;
  }
}



/* second banner */
.banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0px 50px;
  box-sizing: border-box; 
  
}

.textContainer {
  flex: 1;
  min-width: 250px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;

}


.textContainer h1 {
  font-size: 3.3rem;
  font-weight: bold;
  color: #000;
  margin: 0 0 10px 0;
  text-align: left;
}
.heading {
  color: black; 
  font-size: 2rem;
}





.green {
  color: #128c78; 
}


.textContainer p {
  font-size: 16px;
  color: #000;
  margin: 0 0 20px 0;
  text-align: left;
}
.supportButton {
  padding: 10px 20px;
  border: 2px solid #128c78;
  border-radius: 5px;

  color: #128c78;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.supportbtnHover:hover{
  background-color: #128c78;
  color: white;
}
.imageContainer {
  flex: 1;
  min-width: 250px;
  display: flex;
  justify-content: center;

}

.bannerImage {
  max-width: 100%;
  height: auto;
  /* transform: rotatey(180deg); */
 
}

@media (max-width: 768px) {
  .banner {
    padding: 20px; 
  }
  
  .textContainer {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
   
  }
  
  .textContainer h1 {
    font-size: 20px;
  }

  .textContainer p {
    font-size: 14px;
  }

  .supportButton {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 10px;
  
  }
  
  .textContainer h1 {
    font-size: 18px;
  }

  .textContainer p {
    font-size: 12px;
  }

  .supportButton {
    font-size: 12px;
    padding: 8px 16px;
  }
}
  
  .content {
    text-align: center;
    padding: 2rem;
    background-color: #FFB804;
  }
  
  .content h1 {
    font-size: 2.5rem;
    color: #128c78;
    margin: 0.5rem 0;
  }
  
  .content h2 {
    font-size: 2rem;
    color: #FFB804;
    margin: 10px 0;
  }
  
  .content p {
    font-size: 1rem;
    color: #000;
    max-width: 800px;
    margin: 0 auto 20px auto;
    text-align: left;
  }
  
  .trendingButton {
    padding: 10px 20px;
    border: 2px solid #128c78;
    border-radius: 5px;
    color: #128c78;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px 0;
  }
  
  .trendingButton:hover {
    background-color: #128c78;
    color: white;
  }
  
  @media (max-width: 768px) {
    .content h1 {
      font-size: 1.5rem;
    }
  
    .content h2 {
      font-size: 1.2rem;
    }
  
    .content p {
      font-size: 0.9rem;
    }
  
    .trendingButton {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .content h1 {
      font-size: 1.2rem;
    }
  
    .content h2 {
      font-size: 1rem;
    }
  
    .content p {
      font-size: 0.8rem;
    }
  
    .trendingButton {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  
.banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #FFB804;
padding: 0px 50px;
  box-sizing: border-box; 
}

.textContainer {
  flex: 1;
  min-width: 250px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;

}


.textContainer h1 {
  font-size: 3.3rem;
  font-weight: bold;
  color: #000;
  margin: 0 0 10px 0;
  text-align: left;
}
.heading {
  color: black; 
  font-size: 2rem;
}

.green {
  color: #128c78; 
}


.textContainer p {
  font-size: 16px;
  color: #000;
  margin: 0 0 20px 0;
  text-align: left;
}

.textContainer h5{
  font-size: 16px;
  color: #000;
  margin: 0 0 20px 0;
  text-align: left;
}



.supportButton {
  padding: 10px 20px;
  border: 2px solid #128c78;
  border-radius: 5px;

  color: #128c78;
  background-color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.supportbtnHover:hover{
  background-color: #128c78;
  color: white;
}
.imageContainer {
  flex: 1;
  min-width: 250px;
  display: flex;
  justify-content: center;


}
.bannerImage {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .banner {
    padding: 20px; 
  }
  
  .textContainer {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .textContainer h1 {
    font-size: 20px;
  }

  .textContainer p {
    font-size: 14px;
  }

  .supportButton {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 10px;
  }
  
  .textContainer h1 {
    font-size: 18px;
  }

  .textContainer p {
    font-size: 12px;
  }

  .supportButton {
    font-size: 12px;
    padding: 8px 16px;
  }
}

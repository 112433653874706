

   .newswomancontainer {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background: white;
  }
  
  .newswomanmainImage {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .womanimage {
    width: 100%; 
    height: auto; 
    object-fit: cover; 
  }
  
 
  @media (max-width: 768px) {
    .newswomanmainImage {
      margin-top: 0; 
      margin-bottom: 0; 
    }
  }
  
  @media (max-width: 480px) {
    .newswomanmainImage {
      padding-top: 0; 
      padding-bottom: 0; 
    }
    .womanimage {
      object-fit: contain; 
    }
  }
  
  .newswomanbottomImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    background: #FFB804;
    margin: 0; 
    padding: 0; 
  }
  
  .newswomanbottomImage {
    width: 30%;
    margin: 2% 0; 
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .newswomanbottomImage img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .newswomanbottomImage p {
    padding: 5px 10px;
    font-size: 1.2vw; 
    color: white;
  }
  
  @media (max-width: 768px) {
    .newswomanbottomImage {
      width: 45%;
    }
    .newswomanbottomImage p {
      font-size: 3vw;
    }
  }
  
  @media (max-width: 480px) {
    .newswomanbottomImage {
      width: 90%;
    }
    .newswomanbottomImage p {
      font-size: 4vw;
    }
  }
  
  
  
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
  
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
  }
  
  .textContainer,
  .imageContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  .textContainer {
    flex-direction: column;
    text-align: left;
   
    color: #fff;
    align-items: flex-start; 
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .highlight {
    color: #128c78;
  }
  
  .description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .appLink{
    color:#128c78;
    cursor: pointer;
    font-weight: bold;
  }
  .appLink:hover{
  color:#FDC822 ;
  }
  
  .buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem; 
    justify-content: flex-start; 
  }
  
  .button {
    padding: 0.5rem 1rem;
    border: 2px solid #128c78;
    border-radius: 0.5rem;
    color: white;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: #128c78;
  }
  .meet:hover {
    /* background-color: #128c78; */
    border-color: #128c78;
    background-color: white;
    color: #128c78;
  }
  .downloadbutton{
    padding: 0.5rem 1rem;
    border: 2px solid #128c78;
    border-radius: 0.5rem;
    color: #000;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .download:hover {
    background-color: #FDC822;
    border-color: #ffde17;
    color: #128c78;

  }
  
  .arrow {
    display: inline-block;
    /* margin-left: 10px;  */
    transition: transform 0.3s ease; 
  }
  .downloadbutton:hover .arrow {
    transform: rotate(45deg); 
  }
  
  .image {
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
  }
  
 
  @media (min-width: 600px) {
    .header {
      flex-direction: row;
    }
  
    .textContainer {
      text-align: left;
    }
  }
  
  @media (min-width: 1024px) {
    .title {
      font-size: 3rem;
    }
  
    .description {
      font-size: 1.5rem;
    }
  
    .button {
      padding: 0.75rem 1.5rem;
    }
  }
  
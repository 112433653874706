.newsbanner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  /* padding: 20px 50px;  */
  padding: 0px 50px; 
  box-sizing: border-box; 
}

.textContainer {
  flex: 1;
  min-width: 250px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 20px;


}
.suniyeNetajee{
  color: #128c78;
  font-weight: bold;
  cursor: pointer;
  
}
.suniyeNetajee:hover{
  color: #FFB804;
}
.mobileColor{
  color: #128c78;
  
}
.textContainer h1 {
  font-size: 3.3rem;
  font-weight: bold;
  color: #000;
  margin: 0 0 10px 0;
  text-align: left;
}

.textContainer p {
  font-size: 16px;
  color: #000;
  margin: 0 0 20px 0;
  text-align: left;
}

.supportButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.supportButton:hover {
  background-color: #333;
}

.imageContainer {
  flex: 1;
  min-width: 250px;
  display: flex;
  justify-content: center;



}

.bannerImage {
  max-width: 100%;
  height: auto;
  
}

@media (max-width: 768px) {
  .banner {
    padding: 20px; 
  }
  
  .textContainer {
    max-width: 100%;
    margin-right: 0;
    /* margin-bottom: 20px; */
    margin-top:10px
  }
  
  .textContainer h1 {
    font-size: 20px;
  }

  .textContainer p {
    font-size: 14px;
  }

  .supportButton {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 10px;
  }
  
  .textContainer h1 {
    font-size: 18px;
  }

  .textContainer p {
    font-size: 12px;
  }

  .supportButton {
    font-size: 12px;
    padding: 8px 16px;
  }
}


/* section 2 */




.bannerNews {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.breakingNews {
  width: 100%;
  background-color: #FFB804;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breakingNewsText {
  font-weight: bold;
  color: #128c78;
}

.marqueeContainer {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
  animation-play-state: running;
}

.marquee.paused, .marqueeContainer:hover .marquee {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 600px) {
  .breakingNews {
    flex-direction: column;
    align-items: flex-start;
  }

  .breakingNewsText {
    margin-bottom: 10px;
  }

  .marquee {
    animation: marquee 20s linear infinite;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .marquee {
    animation: marquee 18s linear infinite;
  }
}

.content {
  text-align: center;
  margin-top: 20px;
 
}

.content h1 {
  font-size: 2.5rem;
  color: #128c78;
  text-decoration: underline;
}

.content h2 {
  font-size: 2rem;
  color: #FFB804;
  margin: 10px 0;
}

.content p {
  font-size: 1rem;
  color: #000;
  max-width: 800px;
  margin: 0 auto 20px auto;
  text-align: left;
}

.trendingButton {
  padding: 10px 20px;
  border: 2px solid #128c78;
  border-radius: 5px;
  color: #128c78;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.trendingButton:hover {
  background-color: #128c78;
  color: white;
}

@media (max-width: 768px) {
  .content h1 {
    font-size: 1.5rem;
  }

  .content h2 {
    font-size: 1.2rem;
  }

  .content p {
    font-size: 0.9rem;
  }

  .trendingButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .content h1 {
    font-size: 1.2rem;
  }

  .content h2 {
    font-size: 1rem;
  }

  .content p {
    font-size: 0.8rem;
  }

  .trendingButton {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}



/* news section */

.featuresSection {
  background-color: #f5f5f5;
  padding: 30px;
}

.cardContainer {
  display: flex !important; 
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.textContainerNews {
  margin-top: 15px;
  text-align: left;
  padding: 0 10px;
}

.textContainerNews h2 {
  font-size: 1.2rem;
  color: #128c78;
  margin: 10px 0;
}

.textContainerNews p {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

@media (max-width: 768px) {
  .featuresSection {
    flex-direction: column;
    align-items: center;
  }

  .cardContainer {
    width: 90%;
    margin: 10px 0;
  }
}

.OverviewAllType {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #128c78;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.OverviewAllType:hover {
  background-color: darkred;
}

@media (max-width: 768px) {
  .OverviewAllType {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
      /* online business container */
      .banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        padding: 20px;
        /* border-radius: 10px;     */
        margin: 20px;
      }
      
      .textContainer {
        max-width: 60%;
      }
      
      h1 {
        font-size: 2rem;
        color: #000;
        margin-bottom: 10px;
      }
      
      .highlight {
        color: #128c78;
      }
      .highlight:hover{
        color: #FFB804;
      }
      
      p {
        font-size: 1.2rem;
        color: #666;
        margin-bottom: 20px;
      
      }
      
      .callToAction {
        background-color: #128c78;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
      }
      
      .imageContainer {
        max-width: 35%;
      }
      
      .image {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
      
      @media (max-width: 768px) {
        .banner {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      
        .textContainer {
          max-width: 100%;
        }
      
        .imageContainer {
          max-width: 100%;
          margin-top: 20px;
        }
      }
      
   /* news woman */


  
  